<template>
  <div v-if="!loading">
    <Card :title="title" :disabled="!editMode">
      <template #close>
        <a @click.prevent="goBack">
          <v-btn icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </a>
      </template>
      <template #actions>
        <v-btn
          v-permission="{
            permissions: ['productions-update'],
          }"
          @click="editMode = !editMode"
        >
          <span>{{ editMode ? "Görüntüle" : "Düzenle" }}</span>
        </v-btn>
      </template>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-card flat class="pa-4">
          <v-container>
            <v-row>
              <v-col cols="6">
                <h2 class="mb-4">Ürün Bilgisi</h2>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="currentPackage.name"
                      hide-details="auto"
                      label="Ürün Kodu"
                      hint="Ürünün sistemdeki kodu"
                      validate-on-blur
                      required
                      :rules="[(v) => !!v || 'Ürün kodu boş bırakılamaz']"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="currentPackage.display_name"
                      hide-details="auto"
                      label="Ürün İsmi"
                      hint="Ürünün kullanıcıya gösterilecek ismi"
                      validate-on-blur
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="totalPrice"
                      disabled
                      hide-details="auto"
                      label="Ürün İndirimsiz Fiyatı"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="currentPackage.discounted_price"
                      hide-details="auto"
                      label="Ürün İndirimli Fiyatı"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="currentPackage.is_active"
                      hide-details="auto"
                      label="Aktif"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <h2 class="mb-4">Eğitim İçeriği</h2>
                <v-row v-for="(training, index) in trainingList" :key="index">
                  <v-col
                    cols="12"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <v-select
                      v-model="training.id"
                      item-text="name"
                      item-value="id"
                      :items="mappedTrainings"
                      hide-details="auto"
                      default=""
                      label="Eğitim Seçiniz"
                    ></v-select>
                    <i
                      class="mdi mdi-close px-4 pointer-cursor"
                      role="button"
                      @click="removeTraining(index)"
                    ></i>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <b-button
                      :disabled="!editMode"
                      variant="success"
                      @click="addTraining"
                    >
                      <i class="flaticon2-add" />
                      Eğitim Ekle
                    </b-button>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-content-end">
                  <v-btn class="mr-3" color="error" @click="reset">
                    İptal
                  </v-btn>
                  <v-btn class="mr-3" color="primary" @click="save">
                    Kaydet
                  </v-btn>
                  <v-btn color="primary" @click="saveAndClose">
                    Kaydet ve Kapat
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-form>
    </Card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  CREATE_PACKAGE,
  UPDATE_PACKAGE,
  GET_PACKAGE,
} from "@/core/services/store/definitions/packages.module";
import { FETCH_TRAININGS } from "@/core/services/store/definitions/trainings.module";
import { currencyMask } from "@/core/helper";
import Card from "@/view/content/components/Card";

export default {
  components: { Card },
  data() {
    return {
      editMode: false,
      currentPackage: {},
      trainingList: [],
      currencyMask,
      loading: !!this.$route.params.id,
      isFormValid: true,
    };
  },
  beforeMount() {
    this.fetchTrainings();
    if (this.$route.params.id) {
      this.getPackage(Number(this.$route.params.id))
        .then((data) => {
          this.currentPackage = Object.assign({}, data);
          this.trainingList = data.educations.map((item) => {
            return {
              id: item.id,
            };
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }

    this.$route.query.editable
      ? (this.editMode = true)
      : (this.editMode = false);
  },
  methods: {
    ...mapActions([
      FETCH_TRAININGS,
      CREATE_PACKAGE,
      UPDATE_PACKAGE,
      GET_PACKAGE,
    ]),
    addTraining() {
      this.trainingList.push({
        id: "",
      });
    },
    removeTraining(index) {
      this.trainingList.splice(index, 1);
    },
    async save(replaceRoute = false) {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.isFormValid = false;
        return;
      }
      const data = {
        ...this.currentPackage,
        // discounted_price:
        //   parseInt(
        //     this.currentPackage.discounted_price.replace(/[^0-9]/g, "")
        //   ) || this.totalPrice,
        educations: this.trainingList
          .filter((training) => training.id !== "")
          .map((item) => item.id),
      };
      if (this.$route.params.id) {
        data.id = Number(this.$route.params.id);
        try {
          await this.updatePackage(data);
          this.$toast.success("Ürün güncellendi", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } else {
        try {
          const production = await this.createPackage(data);
          if (replaceRoute) {
            this.$router.replace({
              name: "def-package-edit",
              params: {
                id: production.id,
              },
              query: { editable: true },
            });
          }
          this.$toast.success("Ürün yaratıldı", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      }
    },
    async saveAndClose() {
      await this.save(false);
      if (this.isFormValid && !this.stopExecution)
        this.$router.push({ name: "def-packages" });
      this.stopExecution = false;
    },
    reset() {
      if (this.$route.params.id) {
        this.currentPackage = Object.assign({}, this.package);
      } else {
        this.currentPackage = {};
      }
      this.$router.push({ name: "def-packages" });
    },
    goBack() {
      if (
        this.$router.history.current.path.includes("definitions/packages/edit")
      ) {
        this.$router.push({ name: "def-packages" });
      } else {
        this.$router.go(-1);
      }
    },
  },
  computed: {
    ...mapGetters(["packages", "trainings", "package"]),
    title() {
      if (this.$route.params.id) {
        if (this.editMode) return "Ürün Düzenle";
        return "Ürün Detay";
      }
      return "Yeni Ürün Ekle";
    },
    mappedTrainings() {
      return this.trainings.map((item) => {
        return {
          id: item.id,
          name: `${item.name} - ${item.price}	₺ `,
        };
      });
    },
    totalPrice() {
      return this.trainingList
        .filter((training) => training.id !== "")
        .reduce((acc, item) => {
          const training = this.trainings.find((t) => t.id === item.id);
          return acc + Number(training.price);
        }, 0);
    },
  },
  watch: {
    totalPrice(val) {
      if (!this.currentPackage.discounted_price) {
        this.currentPackage.discounted_price = val;
      }
    },
  },
};
</script>

<style></style>
